// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms {
  font-size: 1.5rem;
  margin-top: 1.6rem;
  max-width: 40em;
  color: #515E6D;
}
@media screen and (min-width: 578px) {
  .terms {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/App.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AACF;AACE;EANF;IAOI,aAAA;EAEF;AACF","sourcesContent":[".terms {\n  font-size: 1.5rem;\n  margin-top: 1.6rem;\n  max-width: 40em;\n  color: #515E6D;\n\n  @media screen and (min-width: 578px) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
