// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-feature-box {
  display: flex;
  background-color: #ffffff;
}

@media screen and (min-width: 578px) {
  .new-feature-box {
    display: flex;
    width: 50%;
    background: #ffffff;
  }
}
.trial-feature-box {
  display: flex;
}
.trial-feature-box .right-summary {
  display: flex;
}
@media screen and (min-width: 578px) {
  .trial-feature-box .right-summary {
    margin-left: 60px;
  }
}

@media screen and (min-width: 578px) {
  .trial-feature-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 45%;
    background: #8241aa;
  }
  .trial-feature-box small {
    font-size: 1.4rem;
  }
  .trial-feature-box .feature-box-tick {
    color: green;
    margin-right: 0.3rem;
  }
  .trial-feature-box .feature-separator {
    opacity: 0.5;
    margin-top: 14px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 578px) {
  .trial-feature-box .feature-separator {
    opacity: 0.5;
    margin: 10px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/newFeatureContainer/NewFeatureContainer.scss","webpack://./src/assets/scss/global.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,yBCGM;ADJR;;AAIA;EACE;IACE,aAAA;IACA,UAAA;IACA,mBCJI;EDGN;AACF;AAIA;EACE,aAAA;AAFF;AAIE;EACE,aAAA;AAFJ;AAGI;EAFF;IAGI,iBAAA;EAAJ;AACF;;AAIA;EACE;IACE,aAAA;IACA,sBAAA;IACA,uBAAA;IACA,mBAAA;IACA,aAAA;IACA,UAAA;IACA,mBChCG;ED+BL;EAGE;IACE,iBAAA;EADJ;EAIE;IACE,YAAA;IACA,oBAAA;EAFJ;EAKE;IACE,YAAA;IACA,gBAAA;IACA,mBAAA;EAHJ;AACF;AAOA;EAEI;IACE,YAAA;IACA,cAAA;EANJ;AACF","sourcesContent":["@import 'assets/scss/global';\n\n.new-feature-box {\n  display: flex;\n  background-color: $white;\n}\n\n@media screen and (min-width: 578px) {\n  .new-feature-box {\n    display: flex;\n    width: 50%;\n    background: $white;\n  }\n}\n\n.trial-feature-box {\n  display: flex;\n\n  .right-summary{\n    display: flex;\n    @media screen and (min-width: 578px) {\n      margin-left: 60px;\n    }\n  }\n}\n\n@media screen and (min-width: 578px) {\n  .trial-feature-box {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 2rem;\n    width: 45%;\n    background: $dusk;\n\n    small {\n      font-size: 1.4rem;\n    }\n\n    .feature-box-tick {\n      color: green;\n      margin-right: 0.3rem;\n    }\n\n    .feature-separator{\n      opacity: 0.5;\n      margin-top: 14px;\n      margin-bottom: 10px;\n    }\n  }\n}\n\n@media screen and (max-width: 578px) {\n  .trial-feature-box {\n    .feature-separator{\n      opacity: 0.5;\n      margin: 10px 0;\n    }\n  }\n}\n\n","$candy: #ec0677;\n$rouge: #b93499;\n$dusk: #8241aa;\n$regal: #6100a5;\n$golf: #00aa65;\n$zest: #00bc6f;\n$light-grey: #f9f9f9;\n$white: #ffffff;\n$purple: #A130F5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
